.modalBg {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.modal {
	background-color: white;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	color: black;
	max-width: 90%;
}

.modal-bar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #ccc;
	padding: 10px;
}

.modalTags {
	font-weight: bold;
	font-size: 20px;
}

.closeBtn {
	cursor: pointer;
	padding: 0 10px;
	font-size: 35px;
}

.modal-content {
	padding: 10px;
	text-align: center;
}

.modalImage {
	max-width: calc(70vw - 20px);
	max-height: calc(80vh - 20px);
	border-radius: 0 0 10px 10px;
	object-fit: cover;
}

@media screen and (max-width: 800px) {
	.modalImage {
		max-width: calc(90vw - 20px);
		width: unset;
	}
}
