.appContainer {
	min-height: calc(100vh - 60px);
	padding: 10px;
	box-sizing: border-box;
	width: 100%;
}

.imageGrid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
	grid-gap: 50px;
	margin: 30px 0;
	justify-content: center;
}

.appImageContainer {
	cursor: pointer;
}

.appImage {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@media screen and (max-width: 768px) {
	.imageGrid {
		grid-template-columns: repeat(auto-fill, minmax(80%, 1fr));
	}
}
